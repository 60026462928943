.navbar {
    padding: 0.5rem;
    height: 70px;
  }

/* .custom-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
} */
  
  .navbar-brand {
    font-size: 1.5rem;
  }
  
  .navbar-nav .nav-link {
    font-size: 1.2rem;
    padding: 0.25rem 0.5rem;
  }
  
  .navbar-collapse {
    justify-content: flex-end;
  }
  
  .form-control {
    font-size: 1.2rem;
  }
